import { AppBar, Box, Grid, Toolbar } from '@mui/material';
import Login from './LoginMenu';

const Banner = () => (
  <AppBar position="static" sx={{ bgcolor: '#fff' }}>
    <Toolbar disableGutters sx={{ mt: 2, mb: 2 }}>
      <Grid container sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Grid
          item
          sx={{
            mr: 1,
            ml: 1,
            left: 0,
            width: '172px',
            flexGrow: 1,
          }}
          display="flex"
          alignItems="center"
        >
          <Box
            component="img"
            src="/lineage-logo.png"
            sx={{
              mr: 1,
              ml: 1,
              left: 0,
              width: '172px',
            }}
          />
        </Grid>
        <Grid item>
          <Login />
        </Grid>
      </Grid>
      <Grid container sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Grid
          item
          xs={6}
          sx={{
            left: 0,
            flexGrow: 1,
          }}
          display="flex"
          alignItems="center"
        >
          <Box
            component="img"
            src="/lineage-logo.png"
            sx={{
              ml: 1,
              left: 0,
              width: '172px',
            }}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Login />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default Banner;
