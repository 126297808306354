// @mui
import {useTheme} from '@mui/material/styles';
import {LinearProgress, Stack} from '@mui/material';
//
import {bgBlur} from "../../utils/cssStyles";

// ----------------------------------------------------------------------

export default function LoadingRequest() {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        position: 'absolute',
        ...bgBlur({ color: theme.palette.background.paper }),
      }}
    >
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 320 }} />
    </Stack>
  );
}
