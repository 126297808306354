import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';

import { BrowserRouter } from 'react-router-dom';

import { Box } from '@mui/material';
import PageLayout from './components/PageLayout';
import ThemeProvider from './theme';
import history from './routingHistory';
import SnackbarProvider from './components/snackbar';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTSID,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const App = () => (
  <AppInsightsErrorBoundary
    onError={() => (
      <div style={{ padding: '10px' }}>
        <Box component="img" src="/lineage-logo.png" sx={{ width: '172px' }} />
        <h4>Something went wrong</h4>
      </div>
    )}
    appInsights={reactPlugin}
  >
    <BrowserRouter>
      <ThemeProvider>
        <SnackbarProvider>
          <PageLayout />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </AppInsightsErrorBoundary>
);

export default App;
