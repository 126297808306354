import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, Container, Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useAccount, useMsal } from '@azure/msal-react';
import { useSnackbar } from '../snackbar';
import { RHFSelect } from '../hook-form/RHFSelect';
import FormProvider from '../hook-form/FormProvider';
import { RHFUpload } from '../hook-form/RHFUpload';
import { getFilesInfos } from '../../utils/getFileData';
import { ISite } from '../../@types/Site';
import LoadingRequest from '../loading-screen/LoadingRequest';

const UploadPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const defaultValues = useMemo(() => ({ connectedSite: '', files: [] }), []);
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getToken = useCallback(
    async (msalInstance) => {
      if (account) {
        try {
          const response = await msalInstance.acquireTokenSilent({
            scopes: [],
            account,
          });
          return response.accessToken;
        } catch (error) {
          console.log(JSON.stringify(error));
          return '';
        }
      } else {
        return '';
      }
    },
    [account]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = await getToken(instance);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}sites/upn/${account.username}`,
        {
          headers: {
            Accept: 'application/json;odata.metadata=minimal;',
            AuthorizationSWA: `Bearer ${token}`, // https://github.com/Azure/static-web-apps/issues/34
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSites(data);
      } else {
        enqueueSnackbar(`Error: ${response.statusText} - ${await response.text()}`, {
          variant: 'error',
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [account, enqueueSnackbar, getToken, instance]);

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        connectedSite: Yup.string().required('Please select site'),
        files: Yup.array().min(1, 'Please upload at least 1 file'),
      })
    ),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const token = await getToken(instance);

    data.files.forEach((file) => {
      file.base64 = file.base64.substring(file.base64.indexOf('base64,') + 7);
    });
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AuthorizationSWA: `Bearer ${token}`, // https://github.com/Azure/static-web-apps/issues/34
      },
      body: JSON.stringify({
        user: account.username,
        siteCode: data.connectedSite,
        files: data.files.map((file) => ({
          filename: file.name,
          content: file.base64,
        })),
      }),
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}files`, options)
      .then((response) => {
        if (response.ok) {
          enqueueSnackbar('Files uploaded!');
          reset();
        } else {
          enqueueSnackbar(`Error: ${response.statusText}`, { variant: 'error' });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: 'error' });
        console.log(JSON.stringify(error));
        setIsLoading(false);
      });
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files = values.files || [];
      const newFiles = await getFilesInfos(acceptedFiles);
      setValue('files', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.files]
  );

  const handleRemoveFile = (inputFile: File) => {
    const filtered = values.files && values.files?.filter((file) => file !== inputFile);
    setValue('files', filtered);
  };

  const handleRemoveAllFiles = () => {
    setValue('files', []);
  };

  if (isLoading) {
    return <LoadingRequest />;
  }

  return (
    <Container maxWidth="lg">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <RHFSelect native name="connectedSite" label="Site*">
                  <option value="" />
                  {sites.map((site) => (
                    <option key={site.SiteCode} value={site.SiteCode}>
                      {site.SiteName}
                    </option>
                  ))}
                </RHFSelect>
                <RHFUpload
                  multiple
                  thumbnail
                  name="files"
                  maxSize={10485760}
                  onDrop={handleDrop}
                  onRemove={handleRemoveFile}
                  onRemoveAll={handleRemoveAllFiles}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                    sx={{ maxWidth: '10%' }}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
};

export default UploadPage;
