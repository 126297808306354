import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
};

export const PowerBiLoginRequest: PopupRequest = {
  scopes: process.env.REACT_APP_SCOPES.split(','),
};
