export async function getFilesInfos(files: File[]) {
  const filePromises = files.map(
    (file: File) =>
      // Return a promise per file
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          resolve(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              base64: e?.target?.result,
            })
          );
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      })
  );

  const fileInfos = await Promise.all(filePromises);

  return fileInfos;
}
