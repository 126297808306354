import React, { useMemo } from 'react';
import classnames from 'classnames';
import { pick, isBoolean } from 'lodash';

import { ButtonBase } from '@mui/material';
import mergeClassNames from '../../utils/classnames';
import { Anchor } from '../../components/anchors';

import styles from './Trigger.module.scss';

const Trigger = ({
  className,
  classNames,
  to,
  path,
  pathParams,
  target,
  state,
  bypassRouter,
  preventDefault,
  onClick,
  onDisabledClick,
  onMouseDown,
  component,
  propagate,
  disabled,
  disableRipple,
  'data-testid': dataTestId,
  ...props
}) => {
  const isLink = useMemo(() => to || path, [to, path]);
  const triggerable = useMemo(() => isLink || onClick, [isLink, onClick]);

  const baseComponent = useMemo(() => (to || path ? Anchor : component), [to, path, component]);

  const baseDisableRipple = useMemo(
    () => (isBoolean(disableRipple) ? disableRipple : !triggerable),
    [disableRipple, triggerable]
  );

  const additionalProps = useMemo(() => {
    if (!isLink) return {};

    return {
      to,
      path,
      pathParams,
      target,
      state,
      bypassRouter,
      preventDefault,
    };
  }, [isLink, to, path, pathParams, target, state, bypassRouter, preventDefault]);

  const classes = useMemo(
    () =>
      mergeClassNames(styles, classNames, {
        root: className,
      }),
    [className, classNames]
  );

  const baseClasses = useMemo(() => pick(classes, ['root', 'disabled', 'focusVisible']), [classes]);

  const handleClick = (event) => {
    if (!propagate) event.stopPropagation();
    if (preventDefault) event.preventDefault();

    if ((disabled && !onDisabledClick) || !onClick) return;
    const click = disabled ? onDisabledClick : onClick;
    click(event);
  };

  const handleMouseDown = (event) => {
    if (!propagate) event.stopPropagation();
    if (preventDefault) event.preventDefault();

    if (disabled || !onMouseDown) return;
    onMouseDown(event);
  };

  return (
    <ButtonBase
      {...props}
      {...additionalProps}
      className={classnames(classes.root, {
        [classes.triggerable]: triggerable,
      })}
      classes={baseClasses}
      component={baseComponent}
      disableRipple={baseDisableRipple}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
};

export default Trigger;
