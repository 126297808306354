import { useIsAuthenticated } from '@azure/msal-react';
import { Container, Alert } from '@mui/material';

const PageNotAccessible = () => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return (
      <Container maxWidth={false}>
        <Alert sx={{ border: 1, padding: 2, mx: 2 }} severity="error">
          Click the <strong>LOGIN</strong> button in the upper right to get started.
        </Alert>
      </Container>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default PageNotAccessible;
