import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Route, Routes } from 'react-router';
import Banner from './Banner';
import { AsideNavigation } from './navigations';
import { PORTAL_ROUTE } from '../constants/route';

// @ts-ignore
import styles from './PageTemplate.module.scss';
import PageNotFound from './PageNotFound';
import Profile from './pages/Profile';
import UploadPage from './pages/UploadPage';
import LoginMenu from './LoginMenu';

const PageLayout = () => {
  const [asideOpen, setAsideOpen] = useState(true);

  const items = useMemo(
    () => [
      {
        id: 'home',
        label: 'Back to Portal',
        icon: 'p',
        to: PORTAL_ROUTE,
      },
    ],
    []
  );

  const navigationItems = useMemo(() => items, [items]);
  const isAuthenticated = useIsAuthenticated();
  const handleAsideToggle = () => setAsideOpen(!asideOpen);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigation = queryParams.get('navigation');
  let showNavigation = true;

  if (navigation !== null && navigation !== undefined && navigation !== '') {
    showNavigation = (navigation === '1')
  }

  return (
    <>
      {showNavigation && <Banner />}
      {!showNavigation &&
        <Grid container sx={{ display: { xs: 'flex', md: 'flex' } }}>
          <LoginMenu buttonStyles={{ backgroundColor: 'text.none', '&:hover': { backgroundColor: 'text.secondary' } }} />
        </Grid>}
      <div className={classnames(styles.PageTemplate)} data-testid="PageTemplate">
        <Grid className={classnames(styles.contentRow)} container>
          {isAuthenticated && (
            <>
              {showNavigation && (
                <Grid item className={styles.nav}>
                  <AsideNavigation
                    user={null}
                    items={navigationItems}
                    open={asideOpen}
                    onToggle={handleAsideToggle}
                  />
                </Grid>
              )}
              <Grid className={classnames(styles.content)} item xs={showNavigation ? 9 : 12}>
                <Routes>
                  <Route path="/" element={<UploadPage />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="profile" element={<Profile />} />
                </Routes>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default PageLayout;
